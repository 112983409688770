<template>
    <div class="home">
        <div class="top flex">
            <img class="logo" @click="goHome('')" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" style="color:#45B79C; font-weight: bold">赛事报名</div>
        </div>
    </div>
    <div class="main flex-vcbetween">
        <div class="left">
            <div style="border-bottom: 1px solid #E5E5E5;" class="name flex"> <img src="@/assets/upload/name@2x.png" alt=""> {{Name}}</div>
            <div class="name flex"> <img src="@/assets/upload/phone@2x.png" alt=""> {{Phone}}</div>
        </div>
        <div class="right">
            <div class="block1">上传作品</div>
            <div class="block2" v-for="(item,index) in saidaoList" :key="index">
                <div class="title">{{item.title}}</div>
                <div v-if="!item.release">
                  <div class="name-wrap">
                      <span class="red"></span>
                      <el-input
                      type="text"
                      style="width: 862px;"
                      placeholder="请输入作品名称"
                      v-model="item.name"
                      maxlength="50"
                      show-word-limit
                      ></el-input>
                  </div>
                  <div class="title2">
                      上传文件<span>注: 请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式</span>
                  </div>
                  <div class="flex">
                      <span class="red"></span>
                      <el-upload
                      class="upload-demo"
                      accept=".pdf"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :limit="1"
                      :http-request="modeUpload"
                      :on-exceed="changeData"
                      :data="{index:index}"
                      :before-upload="onBeforeUpload"
                      :on-preview="handlePreview"
                      :on-remove="(file, fileList)=>{handleRemove(file, fileList, index)}"
                      :file-list="fileList"
                      >
                      <div v-if="!item.file" class="upload">
                          <img src="@/assets/upload/add@2x.png" alt="">
                          <div>上传格式为.PDF</div>
                      </div>
                      <div v-else>
                        <img class="pdficon" src="@/assets/upload/pdficon@2x.png" alt="">
                      </div>
                      </el-upload>
                  </div>
                  <div class="release-wrap flex-r">
                      <div class="release" @click="openModel(index)">发布</div>
                  </div>
                </div>
                <div v-else style="padding-bottom: 40px">
                  <div class="file-wrap flex">
                    <span>已上传</span>
                    <div>
                      <div>{{item.file.Title}}</div>
                      <div class="date">{{item.file.CreatedDate}}</div>
                    </div>
                    <img src="@/assets/upload/pdficon@2x.png" alt="">
                    <div>{{item.file.Title}}</div>
                    <!-- <a :href="item.file.ItemUrl" download class="download">下载</a> -->
                    <div @click="download(item.file.ItemUrl)" class="download">下载</div>
                  </div>
                </div>
            </div>
        </div>
        <!-- 参赛者须知 modal -->
        <div v-show="showModal" class="modal-wrap">
          <div class="modal">
            <div class="flex-r">
              <img @click="showModal = false" class="close" src="@/assets/upload/close@2x.png" alt="">
            </div>
            <div class="content">
              <div style="color: red">请确认后发布，一经发布后无法修改</div>
              <div class="title">参赛者须知</div>
              <div class="text">1. 作品应结合常熟文化元素创意创新，表现独特，有一定的艺术感、和纪念意义。作品精神需积极向上，符合相关法律法规要求。<br>
              2. 参赛作品不得含有任何违反《中华人民共和国宪法》、《中华人民共和国著作法》及任何其他相关国家（地区）法律法规内容。<br>
              3. 参赛者提交的参赛作品必须由参赛者本人创作或参与创作，应确认其作品的原创性，且著作权未移交他人；若在比赛或商业应用过程中发生著作权纠纷，其法律责任由参赛者本人承担。<br>
              4. 如因参赛者的抄袭、侵权等行为所引起的法律责任由参赛者承担，大赛主办方有权在任何阶段单方面取消其参赛及获奖资格。如多人合作，需征得所有合作者同意并标明所有合作者姓名。所有投稿人均须填写承诺书，并承担相应的法律责任。<br>
              5. 所有获奖的设计作品与形象口号的知识产权归主办方所有，大赛组委会有权利向知识产权局申请知识产权保护，参赛选手不能再次转让、出版或参加展览、比赛等，否则大赛组委会将追究其法律责任。<br>
              6. 大赛主办方拥有根据竞赛进程与参赛作品的实际情况，有调整奖项数量、等级、取消或添加奖项的权利，对本次大赛和评奖保留最终的解释权。<br>
              7. 当遇到以下情况时，大赛组委会有权收回奖杯、证书、标志使用权、奖金等：正式确认获奖作品侵犯了其他作品的知识产权；设计者在未通知主办方的情况下对获奖作品进行重大修改，并继续在该作品上使用获奖标志或利用其进行宣传。<br>
              8. 参赛者必须保证在各方面均没有违反或侵犯他人的知识产权，包括商标、专利、著作权、多媒体、数据库等各方面，参赛者须严格遵守大赛处理侵权投诉的程序。<br>
              9. 大赛组委会对参赛者提交的相关信息进行严密的管理。大赛组委会有权使用参赛者的信息进行与评奖活动有关的宣传活动，例如发布获奖作品信息、展览展示等。
              </div>
              <div style="margin-top: 27px" class="flex-between">
                <div @click="showModal = false" class="back-btn">返回</div>
                <div @click="Submit" class="agreen-btn">我同意</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- footer -->
    <div id="sign" class="footer">
      <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
      <div class="text-wrap flex-vcbetween">
        <div>
          <div><span>指导单位</span>中共常熟市委宣传部</div>
          <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
          <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
        </div>
        <div class="flex-vc">
          <div><span>官方热线:</span></div>
          <div>
            <div>邮箱: 962991363@qq.com</div>
            <div>微信: 13817518262</div>
          </div>
        </div>
      </div>
      <div class="text-bottom flex-between">
        <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
        <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
      </div>
    </div>
    </div>
</template>
<script>
import { service , Upload} from '@/util/api.js'

export default {
    data() {
        return {
            saidaoList: [
                {
                    title:'赛道一 常熟文创形象“虞小主”卡通形象设计',
                    name: '',
                    release: false,
                    file: ''
                },
                {
                    title:'赛道二 常熟大米产品包装设计',
                    name: '',
                    release: false,
                    file: ''
                },
                {
                    title:'赛道三 虞山碧螺春包装设计',
                    name: '',
                    release: false,
                    file: ''

                },
                {
                    title:'赛道四 常熟文化主题生活用品设计',
                    name: '',
                    release: false,
                    file: ''
                },
                {
                    title:'赛道五 “无界常熟”“文创物品”创新设计开放命题',
                    name: '',
                    release: false,
                    file: ''

                },
            ],
            text: '',
            showModal: false,
            fileList: [],
            Name: '',
            Phone: '',
            file: '',
            userId: '',
            index: '',
        }
    },
    methods: {
        openModel(index) {
          if(this.saidaoList[index].file) {
            this.showModal = true;
            this.index = index
          } else {
            this.$message.error('请上传文件！');
          }

        },
        download(url) {
          window.open(url)
        },
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList,index) {
          this.saidaoList[index].file = ''
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) {
        },
        changeData(file) {
            this.$message.error('请先删除再上传！');
        },
        // 作品附件上传
        async modeUpload(item) {
            this.file = item.file
            let fd = new FormData()
            fd.append('file', item.file)
            fd.append('userId', this.userId)
            fd.append('track', item.data.index+1)
            let res = await Upload(fd)
            if(res) {
              this.saidaoList[item.data.index].file = {Title:res.filename,ItemUrl:res.src}
            }
          // }


        },
        // 提交作品
        async Submit () {
          let parmas = {
            methodName: 'Submit',
            userId: this.userId,
            track: this.index+1,
            title: this.saidaoList[this.index].name,
            itemUrl: this.saidaoList[this.index].file.ItemUrl
          }
          let res = await service(parmas)
          if(res) {
            this.$message.success(res.Value.Tips);
            this.showModal = false;
            this.GetDesign(this.index+1);

          }
        },
        onBeforeUpload(file) {
          if(file.size / 1024 / 1024 > 30) {
            this.$message.error('大小必须小于30M');
            return false;
          }
        },
        // 获取用户信息
        async GetUserInfo() {
          let parmas = {
            methodName: 'GetUserInfo',
            token: localStorage.getItem("Token")
          }
          let res = await service(parmas)
          if(res.Value) {
            this.Name = res.Value.Name 
            this.Phone = res.Value.Phone 
            this.userId = res.Value.Id
            this.GetDesign(1);
            this.GetDesign(2);
            this.GetDesign(3);
            this.GetDesign(4);
            this.GetDesign(5);
          }else {
            this.$router.push({path:'/sign'})
          }
        },
        // 获取用户作品信息
        async GetDesign(track) {
          let parmas = {
            methodName: 'GetDesign',
            userId: this.userId,
            token: localStorage.getItem("Token"),
            track: track
          }
          let res = await service(parmas)
          if(res.Value) {
            this.saidaoList[track-1].release = true
            this.saidaoList[track-1].file = res.Value
          }
        },


    },
    mounted() {
      this.GetUserInfo();

    }
}
</script>
<style>
.el-message {
    font-size: 15px;
}
.el-upload-list__item-name {
  font-size: 17px
}
</style>
<style lang="less" scoped>
/deep/.el-input__inner {
    background-color: #F4F4F4
}
.flex {
  display: flex;
  align-items: center;
}
.flex-r {
    display: flex;
    justify-content: flex-end;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
//   align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  background: #F7F7F7;

  .top {
    background: #FFFFFF;
    height: 134px;
    border-bottom: 1px solid #EBEBEB;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
  .main {
      width: 1200px;
      margin: 38px auto;
    .left {
        width: 230px;
        height: 136px;
        background: #FFFFFF;
        font-size: 20px;
        color: #333333;
        line-height: 68px;
        .name {
            img {
                width: 22px;
                height: 22px;
                margin: 0 13px 0 28px;
            }
        }
    }
    .right {
        text-align: left;
        width: 928px;
        .block1 {
            width: 868px;
            padding: 0 30px;
            line-height: 80px;
            background: #FFFFFF;
            font-size: 22px;
            color: #333333;
        }
        .block2 {
            margin-top: 16px;
            background: #FFFFFF;
            font-size: 18px;
            color: #666666;
            .title {
                line-height: 66px;
                padding: 0 30px;
                border-bottom: 1px solid #E5E5E5;
            }
            .red {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background: #F54531;
                    margin: 0 11px 0 14px;
            }
            .name-wrap {
                margin-top: 26px;
            }
            .title2 {
                width: 863px;
                padding: 29px 0 20px 0;
                margin: 0 auto;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 26px;
                span {
                    font-size: 14px;
                    color: #C1C1C1;
                    margin-left: 22px;
                }
            }
            .upload {
                width: 192px;
                height: 144px;
                background: #F4F4F4;
                font-size: 12px;
                color: #C1C1C1;
                text-align: center;

                img {
                    width: 28px;
                    margin-top: 38px;
                }
            }
            .pdficon {
              width: 60px;
              margin-left: 76px;
            }
            .release-wrap {
                width: 863px;
                .release {
                    width: 156px;
                    line-height: 48px;
                    background: #45B79C;
                    font-size: 18px;
                    color: #FFFFFF;
                    text-align: center;
                    margin-top: -24px;
                    margin-bottom: 20px;
                }
            }
            .file-wrap {
              width: 826px;
              height: 94px;
              box-shadow: 0 2px 8px 4px rgba(0,0,0,0.10);
              border-radius: 4px;
              margin: 30px auto 0;
              font-size: 18px;
              color: #231815;
              padding-left: 36px;
              line-height: 25px;
              position: relative;
              span {
                position: absolute;
                top: 0;
                right: 0;
                width: 66px;
                line-height: 28px;
                background: #45B79C;
                border-radius: 0 4px 0 4px;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
              }
              img {
                width: 40px;
                margin-right: 20px;
              }
              .date {
                font-size: 14px;
                color: #98999A;
                width: 478px;
                line-height: 20px;
              }
              .download {
                color: #24A0FF;
                margin-left: 30px;
                cursor: pointer;
              }
            }
        }
    }
  }

  .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

  }
  .modal-wrap {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    .modal {
      width: 466px;
      height: 440px;
      background: #FFFFFF;
      border-radius: 20px;
      margin: 350px auto;
      .close {
        width: 24px;
        height: 24px;
        margin: 9px 13px;
      }
      .content {
        width: 354px;
        margin-left: 60px;
        margin-top: 7px;
        text-align: left;
        font-size: 18px;
        .title {
          font-size: 24px;
          color: #231815;
          font-weight: bold;
        }
        .text {
          color: #666666;
          margin-top: 22px;
          line-height: 28px;
          height: 224px;
          overflow-y: scroll;
        }
        .back-btn {
          width: 152px;
          line-height: 48px;
          background: #E5E5E5;
          border-radius: 24px;
          font-size: 16px;
          color: #BFBFBF;
          text-align: center;
          font-weight: bold;
        }
        .agreen-btn {
          width: 152px;
          line-height: 48px;
          background: #45B79C;
          border-radius: 24px;
          font-size: 16px;
          color: #FFFFFF;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

}

</style>


